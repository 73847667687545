import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// 查看退货详情
import {purchaseOrderReturnDetail} from '../api'
import { FenxiaoRuturn, getWarehouseListApi } from "@/views/channelReturns/returnEntry/allotFX/api";
moment.locale("zh-cn");
export default {
  name: "channelWarehouse",
  components: {

  },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/purchaseReturn/purchaseReturnEntry",
          name: "",
          title: "渠道订单退货"
        }
      ],
      bg: bg,
      // 基本信息数据
      orderDetail: {},
      pageLoadFlag: false,
      isLoading: false,
      yrckID:'',
      warehouseList:[],
      proList:[],
      warehouseName: '',
      warehouseId: '',
      message:''
    };

  },
  computed:{
    totalBig: function () {
      return this.proList.reduce((sum, item) => {
        return sum + (parseInt(item.bdemandQty) || 0);
      }, 0);
    },
    chukuVolume: function () {
      return this.proList.reduce((sum, item) => {
        return sum + ((item.volume || 0)*(item.bdemandQty||0) );
      }, 0);
    },
  },

  mounted() {
    this.getDeatil(this.$route.query.id)
    this.getWarehouseList()
  },
  methods: {
    // 获取仓库
    getWarehouseList() {
      let requestData = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        warehouseType: 70
      };
      getWarehouseListApi(requestData).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.warehouseList = data.data.map(item => {
          return {
            value: item.cId,
            name: item.name
          };
        });
        if (this.warehouseList.length > 0) {
          this.warehouseName = this.warehouseList[0].name;
          this.warehouseId = this.warehouseList[0].value;
          this.yrckID = this.warehouseList[0].name;
        } else {
          this.warehouseName = "";
          this.warehouseId = "";
          this.yrckID = "";
        }
      });
    },
    //仓库前段筛选
    filterWarehouseOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 仓库下拉框选择
    handleChangeWarehouse(value, e) {
      this.warehouseId = e.key;
      this.warehouseName = value;
      this.warehouseSelect();
    },
    //仓库选择
    warehouseSelect() {
      this.warehouseSelectSHow = !this.warehouseSelectSHow;
    },
    // 获取退货详情
    getDeatil(id) {
      this.pageLoadFlag = true
      purchaseOrderReturnDetail({ purchaseOrderId:id ,cisCode: this.$store.state.user.userInfo.customer.cisCode}).then(res=>{
        this.pageLoadFlag = false
        let response = JSON.parse(res.data);
        this.orderDetail = response.code == 0 ? response.data: {}
        this.proList=response.data.returnOrderItemList
        if(this.orderDetail && this.proList.length>0){
          this.proList.forEach(item=>{
            item.bdemandQt = 0
          })
        }
      })
    },
    // confirm
    confirm(id,index){
      this.orderDetail.returnOrderItemList.splice(index,1)
    },
    submitOrder(){
      if(this.proList.length <= 0 ){
        this.$message.warning('至少有一件退货商品')
        return
      }
      for (let i = 0; i<this.proList.length;i++){
        if (this.proList[i].bdemandQty <=0){
          this.$message.warning(    `第${i+1}行退货数量不能为0`)
          return
        }
      }
      let ProductItem = []
      this.proList.forEach(item=>{
        ProductItem.push({
          model: item.model,
          productCode: item.productCode,
          productName: item.productName,
          colour: item.colour,
          inboundQty: item.inboundQty, // 采购单入库数量
          breturnQty: item.breturnQty, // 已退货数量
          borderedQty: item.bdemandQty, // 退货数量
          bprice: Number(item.bprice), // 价格
          amount: item.bdemandQty * Number(item.bprice),
          invStatusId: item.invStatusId,
          invStatusName: item.invStatusName,
          warehouseId: this.warehouseId, // 仓库id
        })
      })
      let ruturnData = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        purchaseOrderId: this.orderDetail.id,
        returnOrder: {
          message: this.message,
          outWarehouse:this.warehouseId,
          returnItemList: ProductItem
        }
      }
      // return
      this.isLoading = true
      FenxiaoRuturn(ruturnData).then(res => {
        this.isLoading = false
        const data = JSON.parse(res.data);
        if (data.code == 0) {
          this.$router.push({
            path: "/salesReturn/success",
            query: {
              b2bOrderCode: data.msg,
              source:'caigou'
            }
          });

        } else {
          this.$message.warning(data.msg || '提交失败，请重新选择订单进行退货');
          this.isLoading = false
        }
      })
    },
    //取消
    orderCancle(){
      window.close()

    }
  },
};